import Vue from 'vue'
import VueI18n from 'vue-i18n'
import ru from "../locale/ru";
import en from "../locale/en";
import store from "../store";

Vue.use(VueI18n)

const messages = {
    en: en, ru: ru
};

export const i18n = new VueI18n({
    locale: store.getters.locale,
    messages: {ru, en}
})

export default i18n